import React from "react";
import {
  TableRow,
  TableCell,
  IconButton,
  Collapse,
  Box,
  Typography,
  Divider,
  Avatar,
  AvatarGroup,
  Tooltip,
  avatarClasses,
  Stack,
} from "@mui/material";
import { ExpandLess, ExpandMore, InfoOutlined, AttachFile as AttachFileIcon } from "@mui/icons-material";
import ActionMenu from "src/components/action-menu";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import { failReasonStatus, formatCurrency, formatDate, getStatus, statusReview } from "src/utils/utils-returns";
import { AnnouncementItemShippingInfo } from "src/sections/dashboard/chat/AnnouncementItem/AnnouncementItemShippingInfo";
import styled from "styled-components";
import ImageAvatar from "./avatar-row";
import { useNavigate } from "react-router";
import { useChatKey } from "src/contexts/chat-key-context";
import { api } from "src/services/api";

export const TitleWrapper = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;
`;

export const SkuWrapper = styled.span`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
  -webkit-box-orient: vertical;
  word-break: break-all;
`;


const ReturnRow = ({
  row,
  index,
  expandedRow,
  handleExpandClick,
  attachmentsExist,
  handleOpenAttachmentListModal,
  handleOpenDetailDrawer,
  handleOpenDescriptionModal,
  handleOpenStatusModal,
  handleOpenInputFileModal,
  handleOpenReviewModal,
  hasFulfillment,
}) => {
  const navigate = useNavigate(); 
  const { chatKey, setChatKey } = useChatKey();
  const handleReturnToClaim = () => {
    setChatKey(row.conversation_id);
    navigate(`/v1/claims?key=${row.conversation_id}`);
  };

  const HandleRedirectToMessages = async (row) => {
    try {
      const response = await api.get(`/v1/claims/redirectToMessages/${row.codPedido}/${row.claimId}`);
      
      const url = new URL(window.location.origin + response.data.redirect);
      const conversationId = url.searchParams.get('key');

      if (conversationId) {
        setChatKey(conversationId);
        navigate(response.data.redirect);
      } else {
        console.error("Conversation ID não encontrado na resposta.");
      }
    } catch (error) {
      console.error("Erro ao redirecionar para as mensagens:", error);
    }
  };
  
  return (
    <>
      <TableRow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleExpandClick(index)}
          >
            {expandedRow === index ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
       </TableCell>
       <TableCell sx={{ padding: "28px 0px"}}>
       {attachmentsExist[row.id] && (
            <Tooltip title="Exibir anexos">
              <IconButton sx={{ width: "16px", height: "16px" }} onClick={() => handleOpenAttachmentListModal(row)}>
                <AttachFileIcon />
              </IconButton>
            </Tooltip>
          )}
        </TableCell>   
       <Tooltip title={row.account} arrow>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <AvatarGroup
              max={2}
              sx={{
                [`& .${avatarClasses.root}`]: {
                  height: 40,
                  width: 40,
                },
              }}
            >
              <ImageAvatar row={row} />
              </AvatarGroup>
            </Box>
        </TableCell>
        </Tooltip>          
        <TableCell sx={{ width: "310px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Avatar
              variant="rounded"
              style={{
                height: "40px",
                width: "40px",
                border: "1px solid #ddd",
              }}
            >
              <img
                src={row.thumbnail}
                alt="Thumbnail"
                style={{ height: "40px" }}
              />
            </Avatar>
            <div>
              <TitleWrapper>{row.title}</TitleWrapper>
            </div>
          </Box>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Stack>
            <Stack direction="row">
              <strong>Marketplace:&nbsp;</strong>
              <span>{getStatus(row.statusMP)}</span>
             </Stack>
            <Stack direction="row">
              <strong>Weello:&nbsp;</strong>
              <span>{getStatus(row.statusWeello)}</span>
            </Stack>
            <Stack direction="row">
              <strong>Revisão:&nbsp;</strong>
              <span>
                {row.review_status
                  ? row.user_name === null && (row.review_status === "claimed" || row.review_status === "failed" || row.review_status === "success")
                    ? "Revisão de devolução enviada pelo marketplace."
                    : (row.review_status === "claimed" || row.review_status === "failed") && row.fail_reason
                    ? failReasonStatus(row.fail_reason)
                    : statusReview(row.review_status)
                  : "Ainda não revisado"}
              </span>
            </Stack>
          </Stack>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          <Stack>
            <strong>{row.codPedido}</strong>
            {row.customer}
          </Stack>
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatDate(row.updated_at)}
        </TableCell>
        <TableCell sx={{ whiteSpace: "nowrap" }}>
          {formatCurrency(row.total_amount)}
        </TableCell>
        <TableCell  align="center" sx={{ whiteSpace: "nowrap" }}>
          {row.quantity}
        </TableCell>
        <TableCell sx={{ width: "210px" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <SkuWrapper>{row?.sku}</SkuWrapper>
            </Box>
          </TableCell>       
        {hasFulfillment && (
          <TableCell sx={{ whiteSpace: "nowrap" }}>
            <AnnouncementItemShippingInfo
              freeShipping={null}
              logisticType={row?.logistic_type}
            />
          </TableCell>
        )}        
        <TableCell align="center">
        <Stack direction="row">
        <Tooltip title="Redirecionar para mensagens">
              <IconButton onClick={() =>HandleRedirectToMessages(row)}>
                <MessageOutlinedIcon />
              </IconButton>
            </Tooltip>
        <Tooltip title="Retornar para reclamacao">
              <IconButton onClick={handleReturnToClaim}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            </Tooltip>        
        <Tooltip title="Detalhes">
                <IconButton
                  onClick={() => handleOpenDetailDrawer(row)}
                  sx={{ ml: 1, padding: 0 }}
                >
                  <InfoOutlined fontSize="small" /> 
                </IconButton>
         </Tooltip>
          <ActionMenu
            row={row}
            handleOpenDescriptionModal={handleOpenDescriptionModal}
            handleOpenStatusModal={handleOpenStatusModal}
            handleOpenInputFileModal={handleOpenInputFileModal}
            handleOpenReviewModal={handleOpenReviewModal}
          />
          </Stack>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, marginLeft: 30 }}
          colSpan={13}
        >
          <Collapse
            in={expandedRow === index}
            timeout="auto"
            unmountOnExit
            sx={{ width: "60%" }}
          >
            <Box margin={1} marginLeft="40px">
              <Typography variant="body2">
                <strong>Descrição</strong>
              </Typography>
              <Typography variant="body1">{row.description || ""}</Typography>
              <Divider />
              <Typography variant="body2" marginTop={1}>
                <strong>Motivo que gerou a devolução</strong>
              </Typography>
              <Typography variant="body1">{row?.reason || ""}</Typography>
             </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default ReturnRow;
